<template>
  <v-dialog
    :value="!!dialog"
    persistent
    max-width="724px"
  >
    <v-card v-if="dialog" class="pa-0">
      <v-card-title class="mb-1 d-flex align-center justify-space-between">
        <h2 class="font-weight-bold">Transcription</h2>

        <v-tooltip top content-class="v-tooltip--top">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="modal-close"
              @click="close"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="24px">close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-card-title>

      <v-card-subtitle>
        <span>{{ dialog.mailbox_name }} · {{ time }}</span>
      </v-card-subtitle>

      <v-card-text>
        <p class="mb-0">{{ transcription }}</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TranscriptionModal',
  props: {
    dialog: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
  }),
  computed: {
    time () {
      const dateTime = new Date(this.dialog.created).toISOString().split('T')
      return `${dateTime[0]} ${dateTime[1].split('.')[0]}`
    },
    transcription () {
      console.log(this.dialog)
      const [a, b] = this.dialog.transcription.split('; ')
      const [aName, aValue] = a.split(': ')
      return aValue
    }
  },
  methods: {
    close () {
      this.$emit('update:editDialog', null)
    }
  }
}
</script>

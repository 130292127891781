<template>
  <v-list-item class="px-0">
    <v-list-item-avatar width="40px" height="40px" class="mr-md-2 mr-lg-4">

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            height="40px"
            width="40px"
            class="rounded-circle"
            :class="paused ? 'grey-button' : 'violet-button'"
            @click="togglePlaying"
          >
            <v-icon
              :color="paused ? '#647381' : '#FFF'"
            >
              {{ paused ? 'play_arrow' : 'pause' }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ paused ? 'Play' : 'Pause' }}</span>
      </v-tooltip>
    </v-list-item-avatar>

    <v-list-item-content class="d-flex flex-row">
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          :md="isView === 'HistoryComponent' ? '12' : '6'"
        >
          <v-list-item-title
            class="fz-14 d-flex flex-wrap"
            :class="isView === 'HistoryComponent' ? 'd-flex flex-column' : ''"
          >
            <span class="font-weight-bold dark-text mr-3">{{ audioValue.date }}</span>
            <span class="grey--text text--darken-1">{{ audioValue.time }}</span>
          </v-list-item-title>
          <v-list-item-subtitle
            class="fz-14 grey--text text--darken-1 mt-1"
            style="letter-spacing: -0.15px"
            v-if="isView !== 'HistoryComponent'"
          >
            {{ audioValue.mailbox_name }}
          </v-list-item-subtitle>
        </v-col>

        <v-col
          cols="12"
          :md="isView === 'HistoryComponent' ? '0' : '6'"
          :class="isView === 'HistoryComponent' ? 'pa-0' : 'pr-5 pb-2'"
          class="d-flex flex-column justify-center align-self-end"
        >
          <v-row
            v-if="paused && ((isView === 'Voicemails') && $store.state.auth.currentUser && $store.state.auth.currentUser.role === 'account_admin')"
            class="mb-0"
          >
            <v-col
              v-if="audioValue.text"
              col="12"
              md="6"
              class="d-flex flex-column justify-center pt-0 pb-1 fz-14"
            >
              <span class="grey--text text--darken-1">Number</span>
              <span>{{ audioValue.text }}</span>
            </v-col>

            <v-col
              col="12"
              md="6"
              class="d-flex flex-column justify-center pt-0 pb-1 fz-14"
            >
              <span class="grey--text text--darken-1">Duration</span>
              <span>{{ toHourMinSec(duration) }}</span>
            </v-col>
          </v-row>

          <div v-show="!paused">
            <div
              class="d-flex justify-space-between px-2 grey--text text--darken-1"
              v-if="isView !== 'HistoryComponent'"
            >
              <span>{{ toHourMinSec(currentTimeCode) }}</span>
              <span>{{ toHourMinSec(duration) }}</span>
            </div>

            <v-slider
              class="v-slider--track"
              :class="isView === 'HistoryComponent' ? 'v-slider--track-absolute' : ''"
              :max="duration"
              v-model="currentTimeCode"
              hide-details
              ticks
            >
              <template v-slot:thumb-label v-if="isView === 'HistoryComponent'">
                <span>{{ toHourMinSec(currentTimeCode) }}</span>
                -
                <span>{{ toHourMinSec(duration) }}</span>
              </template>
            </v-slider>

            <audio
              ref="audio"
              :src="recording"
              @loadedmetadata="loadMetaData"
              @ended="ended"
            >
            </audio>
          </div>
        </v-col>
      </v-row>
    </v-list-item-content>

    <v-list-item-action
      v-if="isView !== 'Header'"
      class="d-flex flex-row align-center"
    >
      <v-tooltip top content-class="v-tooltip--top">
        <template v-slot:activator="{ on, attrs}">
          <v-btn
            :text="$vuetify.breakpoint.mdOnly"
            :class="!$vuetify.breakpoint.mdOnly ? 'grey-button' : ''"
            class="ml-3 ml-lg-3 ml-md-0 px-0"
            height="36px"
            :min-width="$vuetify.breakpoint.mdOnly ? '20px' : '44px'"
            v-bind="attrs"
            v-on="on"
            @click="download"
          >
            <v-icon size="22px" color="#919EAB">fa-download</v-icon>
          </v-btn>
        </template>

        <span>Download</span>
      </v-tooltip>
      <v-tooltip
        top
        content-class="v-tooltip--top"
        v-if="isView === 'Dashboard' || isView === 'Voicemails' && !paused && $store.state.auth.currentUser && $store.state.auth.currentUser.role === 'account_admin'"
      >
        <template v-slot:activator="{ on, attrs}">
          <v-btn
            class="grey-button px-0 ml-3"
            height="36px"
            min-width="44px"
            v-bind="attrs"
            v-on="on"
            @click="edit"
          >
            <v-icon size="22px" color="#919EAB">voicemail</v-icon>
          </v-btn>
        </template>

        <span>Transcribe</span>
      </v-tooltip>

      <v-menu
        bottom
        left
        max-width="205px"
        nudge-left="-4px"
        nudge-bottom="5px"
        :rounded="'1'"
        offset-y
        :close-on-content-click="true"
        content-class="elevation-2 v-menu--triangle"
        v-if="isView !== 'Dashboard' || $store.state.auth.currentUser && $store.state.auth.currentUser.role === 'account_admin'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :text="$vuetify.breakpoint.mdOnly"
            :min-width="$vuetify.breakpoint.mdOnly ? '20px' : '44px'"
            :class="!$vuetify.breakpoint.mdOnly ? 'grey-button' : ''"
            class="px-0 ml-3"
            height="36px"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon size="22px" color="#919EAB">more_horiz</v-icon>
          </v-btn>
        </template>

        <v-card elevation="0">
          <v-list-item-content class="justify-center py-1">
            <!-- <v-btn
              depressed
              text
              height="40px"
              class="text-none fill-width justify-start v-btn--violet-hover fz-14"
              tile
              v-if="$store.state.auth.currentUser && $store.state.auth.currentUser.role === 'account_admin'"
              :href="recording"
              @click="download"
            >
              Download
            </v-btn> -->

            <v-btn
              depressed
              text
              height="40px"
              class="text-none fill-width justify-start v-btn--violet-hover fz-14"
              tile
              @click="sendEmail"
            >
              Send via email
            </v-btn>

            <v-btn
              depressed
              text
              height="40px"
              class="text-none fill-width justify-start v-btn--violet-hover fz-14"
              tile
              @click="sendMessage"
            >
              Send as message
            </v-btn>

            <v-divider class="mt-0 mb-2"></v-divider>

            <v-btn
              depressed
              text
              height="40px"
              class="text-none fill-width justify-start v-btn--violet-hover fz-14"
              tile
              @click="removeTrack"
            >
              Remove
            </v-btn>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TrackItem',
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: null
    },
    isView: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    window: window,
    actions: [
      {
        title: 'Send via email',
        action: ''
      },
      {
        title: 'Send as message',
        action: ''
      },
      {
        title: 'Remove',
        action: ''
      }
    ],
    duration: 0,
    paused: true,
    timeCode: 0,
    recording: ''
  }),
  created () {
    this.duration = this.value.duration
    if (this.isView === 'Voicemails') this.recording = this.value.a_leg_record_url
  },
  computed: {
    currentTimeCode: {
      get () {
        return this.timeCode
      },
      set (value) {
        if (this.duration) {
          this.$refs.audio.currentTime = value
        }
      }
    },
    audioValue () {
      const dateTime = new Date(this.value.created).toISOString().split('T')
      return {
        ...this.value,
        date: dateTime[0],
        time: dateTime[1].split('.')[0]
      }
    }
  },
  methods: {
    ...mapActions(['getRecording']),
    async togglePlaying () {
      if (this.recording) {
        this.toggle()
        return
      }
      await this.loadRecording()
        .then(() => {
          setTimeout(() => {
            this.toggle()
          }, 200)
        })
    },
    async loadRecording () {
      if (!this.value || !this.value.uuid) return
      await this.getRecording(this.value.uuid)
        .then((res) => {
          var blob = new Blob([res.data], { type: 'application/octet-stream' })
          this.recording = window.URL.createObjectURL(blob)
        }).catch(err => {
          throw new Error(err)
        })
    },
    toggle () {
      if (this.$refs.audio.paused) {
        this.$refs.audio.play()
        this.paused = false
      } else {
        this.$refs.audio.pause()
        this.paused = true
      }
      this.$emit('onPauseChange', this.paused)
    },
    removeTrack () {
      this.$emit('removeTrack', this.value.uuid)
    },
    sendEmail () {
      this.$emit('sendEmailDialog', true)
    },
    sendMessage () {
      this.$emit('update:sendMessageDialog', true)
    },
    edit () {
      this.$emit('update:editDialog', this.value)
    },
    loadMetaData (e) {
      const track = this
      if (e.target.duration === Infinity) {
        e.target.currentTime = 1e101
        e.target.ontimeupdate = function () {
          this.ontimeupdate = () => {
            track.duration = Math.round(e.target.duration)
          }
          e.target.currentTime = 0
        }
      }
    },
    ended () {
      this.$refs.audio.pause()
      this.paused = true
    },
    async download () {
      // if (!this.recording) await this.loadRecording()
      const link = document.createElement('a')
      link.href = this.audioValue.a_leg_record_url
      link.download = this.audioValue.originalFilename
      link.click()
      link.remove()
    }
  },
  mounted () {
    if (!this.$refs.audio) return
    this.$refs.audio.addEventListener('loadeddata', () => {
      if (this.$refs.audio.duration < Infinity) this.duration = Math.round(this.$refs.audio.duration)
      // The duration variable now holds the duration (in seconds) of the audio clip
    })
    this.$refs.audio.addEventListener('timeupdate', () => {
      this.timeCode = Math.round(this.$refs.audio.currentTime)
    })
  }
}
</script>
